import { Maybe } from './types'

export const trySetNewChatInitiated = (botId: Maybe<string>, chatId: Maybe<string>): void => {
  if (!botId || !chatId) {
    return
  }

  const chatInitiated = localStorage.getItem(`widgetChatInitiated-${botId}`) === 'true'
  if (!chatInitiated) {
    botId && localStorage.setItem(`widgetChatInitiated-${botId}`, 'true')
    window.parent.postMessage(
      {
        type: 'new-chat-initiated',
        chatId,
        botId,
      },
      '*',
    )
  }
}
