import { useRef, useState } from 'react'
import { useRequestSent } from './useRequestSent'
import { useRequestCompleted } from './useRequestCompleted'
import { trySetNewChatInitiated } from 'helper/trySetNewChatInitiated'

export interface MetricsData {
  requestSentAt: Date | null
  firstChunkAt: Date | null
  completedAt: Date | null
  messageId: string | null
}

interface MetricPayload {
  botId: string
  chatId: string
  messageId: string
  requestSentAt: Date
  firstChunkAt: Date
  completedAt: Date | null
}

const sendMetrics = async(payload: MetricPayload, backendUrl: string) => {
  fetch(`${backendUrl}/api/public/bot/${payload.botId}/chat/${payload.chatId}/message/${payload.messageId}/metrics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      requestSentAt: payload.requestSentAt.toISOString(),
      firstChunkAt: payload.firstChunkAt.toISOString(),
      completedAt: payload.completedAt?.toISOString(),
    }),
    cache: 'no-store',
  }).catch(console.error)
}

export const useMetrics = ({ botId, chatId, backendURL }: { botId: string; chatId: string; backendURL: string }) => {
  const botIdRef = useRef<string>(botId)
  const chatIdRef = useRef<string>(chatId)

  botIdRef.current = botId
  chatIdRef.current = chatId

  const [metricsData, setMetricsData] = useState<MetricsData>({
    requestSentAt: null,
    firstChunkAt: null,
    completedAt: null,
    messageId: null,
  })

  const doSendMetrics = (reset: boolean) => {
    sendMetrics(
      {
        botId: botIdRef.current,
        chatId: chatIdRef.current,
        messageId: metricsData.messageId!,
        requestSentAt: metricsData.requestSentAt!,
        firstChunkAt: metricsData.firstChunkAt!,
        completedAt: metricsData.completedAt!,
      },
      backendURL,
    ).then(() => {
      if (reset) {
        setMetricsData({
          requestSentAt: null,
          firstChunkAt: null,
          completedAt: null,
          messageId: null,
        })
      }
    })
  }

  useRequestSent(metricsData, doSendMetrics)
  useRequestCompleted(metricsData, doSendMetrics)

  const setRequestSentAt = (date: Date | null) => {
    setMetricsData(prev => ({ ...prev, requestSentAt: date }))
    trySetNewChatInitiated(botIdRef.current, chatIdRef.current)
  }

  return {
    firstChunkAt: metricsData.firstChunkAt,
    setRequestSentAt,
    setFirstChunkAt: (date: Date | null) => setMetricsData(prev => ({ ...prev, firstChunkAt: date })),
    setCompletedAt: (date: Date | null) => setMetricsData(prev => ({ ...prev, completedAt: date })),
    setMessageId: (id: string | null) => setMetricsData(prev => ({ ...prev, messageId: id })),
  }
}
